/**
 * 获取签章图片的宽高
 * @param {string} imgSrc
 */
const getSealRect = imgSrc => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.onload = () => {
      // resolve({
      //   width: img.width,
      //   height: img.height,
      // })
      resolve({
        width: img.width >= 300 ? 168 : img.width,
        height: img.height >= 300 ? 168 : img.height,
      })
    }
    img.src = imgSrc
  })
}
export default getSealRect
